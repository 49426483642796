@charset "UTF-8";
/* ==========================================================================
 *
 * Utility: Extend - layout
 *
mediaの外で作る必要がある
.base{
	@extend %center
}
========================================================================== */
/*
 * RESET
*/
body {
  margin: 0;
  padding: 0;
  font-size: 62.5%; }

div, h1, h2, h3, h4, h5, h6, p, dl, dt, dd, ul, ol, li, blockquote, pre, table, caption, th, td, address, form, fieldset, legend, object, header, footer, section, article, nav, figure, canvas {
  margin: 0;
  padding: 0; }

table {
  border-spacing: 0;
  border-collapse: collapse; }

fieldset, img {
  border: none;
  vertical-align: top; }

address, caption, cite, code, dfn, em, strong, th, var {
  font-weight: normal;
  font-style: normal; }

ol, ul, li {
  list-style: none; }

caption, th, td {
  vertical-align: top;
  text-align: left; }

h1, h2, h3, h4, h5, h6, small {
  font-weight: normal;
  font-size: 100%; }

i {
  font-style: normal; }

q:before, q:after {
  content: ''; }

abbr, acronym {
  border: none; }

sup {
  vertical-align: top; }

sub {
  vertical-align: baseline; }

a, object, embed {
  outline: none; }

a:hover, a:active, a:focus {
  outline: none; }

header, footer, section, article, nav, figure, canvas, main {
  display: block; }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  -webkit-text-size-adjust: 100%;
  font-size: 11px;
  font-family: -apple-system, "Segoe UI", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", メイリオ, meiryo, sans-serif;
  color: #1a1919;
  background: #ffffff; }

*, *::before, *::after {
  box-sizing: border-box; }

.-transform {
  will-change: transform; }

a {
  color: inherit;
  text-decoration: none; }

/*
LANGUAGE / DEVICE
*/
.lang-jp [data-lang="en"] {
  display: none !important; }

.lang-en [data-lang="jp"] {
  display: none !important; }

@media (min-width: 751px) {
  [data-device="sp"] {
    display: none !important; } }

@media (max-width: 750px) {
  [data-device="pc"] {
    display: none !important; } }

/*
@keyframes xxx {
  0%   {}

  50%  {}

  100% {}
  AAA
}
*/
.acc {
  cursor: pointer;
  position: relative; }
  .acc .acc-header {
    display: flex; }
    .acc .acc-header span {
      display: inline-block; }
      .acc .acc-header span:nth-child(1) {
        width: 62.5px; }
      .acc .acc-header span:nth-child(2) {
        width: 62.5px; }
  .acc .acc-content {
    width: 100%;
    padding-left: 125px;
    padding-top: 20px;
    line-height: 1.75; }
    @media (max-width: 750px) {
      .acc .acc-content {
        padding-bottom: 0vw; } }
  @media (min-width: 751px) {
    .acc:after {
      pointer-events: none;
      opacity: 0;
      content: '';
      background: #fff;
      background: #eaeaea;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: 0.2s all linear;
      mix-blend-mode: darken; }
    .acc:hover:after {
      opacity: 0.4; }
    .acc.-open {
      cursor: auto; }
      .acc.-open:hover:after {
        opacity: 0; }
    .acc .acc-close:hover {
      opacity: 0.4; }
    .acc .acc-close {
      transition: 0.2s all linear;
      height: 40px;
      mix-blend-mode: darken;
      width: 100%;
      background: #eaeaea;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
      opacity: 0; } }
  @media (max-width: 750px) {
    .acc .acc-close {
      transition: 0.2s all linear;
      height: 13.33333vw;
      mix-blend-mode: darken;
      width: 100%;
      background: #eaeaea;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
      opacity: 0; } }

@media (min-width: 751px) {
  .base {
    transform: rotate(45deg); }
    .base::after {
      content: "";
      clear: both;
      display: block; } }

.content-menu {
  position: fixed;
  top: 280px;
  left: calc(100% / 12 * 3);
  width: calc(100% / 12 * 2);
  line-height: 3; }
  @media (min-width: 1500px) {
    .content-menu {
      left: calc(50% - 750px + 1500px / 12 * 3);
      width: calc(1500px / 12 * 2); } }
  .content-menu h3 {
    border-bottom: 1px solid #1a1919; }
  .content-menu ul.content-menu-list li {
    border-bottom: 1px solid #1a1919; }
    .content-menu ul.content-menu-list li a {
      display: block;
      opacity: 0.4; }
      .content-menu ul.content-menu-list li a:hover {
        opacity: 1; }
    .content-menu ul.content-menu-list li.-on a {
      opacity: 1; }
  @media (max-width: 750px) {
    .content-menu {
      display: none; } }

@media (min-width: 751px) {
  .content {
    padding-top: 280px;
    margin-left: calc(100% / 12 * 5.5);
    width: calc(100% / 12 * 5.5);
    line-height: 3;
    margin-bottom: 60px; } }
  @media (min-width: 751px) and (min-width: 1500px) {
    .content {
      margin-left: calc(50% - 750px + 1500px / 12 * 5.5);
      width: calc(1500px / 12 * 5.5); } }

@media (min-width: 751px) {
    .content h2.content-ttl {
      border-bottom: 1px solid #1a1919; }
    .content p.content-text {
      border-bottom: 1px solid #1a1919; } }

@media (max-width: 750px) {
  .content {
    padding-top: 50vh;
    margin-left: 6.66667vw;
    width: 93.33333vw;
    line-height: 3;
    margin-bottom: 8vw; }
    .content h2.content-ttl {
      border-bottom: 1px solid #1a1919; }
    .content p.content-text {
      border-bottom: 1px solid #1a1919; } }

@media (min-width: 751px) {
  .footer {
    margin-left: calc(100% / 12 * 5.5);
    width: calc(100% / 12 * 5.5);
    padding-bottom: calc(100vh - 280px); } }
  @media (min-width: 751px) and (min-width: 1500px) {
    .footer {
      margin-left: calc(50% - 750px + 1500px / 12 * 5.5);
      width: calc(1500px / 12 * 5.5); } }

@media (min-width: 751px) {
  .header .header-ttl {
    position: fixed;
    top: calc(100vw / 12 * 0.5);
    left: calc(100% / 12 * 0.5); }
    .header .header-ttl img {
      width: 200px; }
  .header .header-menu {
    height: 50px;
    width: 50px;
    position: fixed;
    right: 4.34028vw;
    transform: translateX(50%);
    top: calc(100vw / 12 * 0.5 - 13px);
    margin-bottom: 25px; }
    .header .header-menu span:nth-child(1) {
      display: inline-block;
      background: #1a1919;
      width: 100%;
      height: 1px;
      position: absolute;
      top: 10px; }
    .header .header-menu span:nth-child(2) {
      display: inline-block;
      background: #1a1919;
      width: 100%;
      height: 1px;
      position: absolute;
      top: 20px; }
    .header .header-menu span:nth-child(3) {
      display: inline-block;
      background: #1a1919;
      width: 100%;
      height: 1px;
      position: absolute;
      top: 30px; } }

@media (max-width: 750px) {
  .header .header-ttl {
    position: fixed;
    top: 5.33333vw;
    left: 6.66667vw; }
    .header .header-ttl img {
      width: 40vw; }
  .header .header-menu {
    height: 6.66667vw;
    width: 13.33333vw;
    position: fixed;
    right: 6.66667vw;
    top: 4vw;
    z-index: 3; }
    .header .header-menu span:nth-child(1) {
      display: inline-block;
      background: #1a1919;
      width: 100%;
      height: 1px;
      position: absolute;
      top: 1.33333vw; }
    .header .header-menu span:nth-child(2) {
      display: inline-block;
      background: #1a1919;
      width: 100%;
      height: 1px;
      position: absolute;
      top: 2.66667vw; }
    .header .header-menu span:nth-child(3) {
      display: inline-block;
      background: #1a1919;
      width: 100%;
      height: 1px;
      position: absolute;
      top: 4vw; } }

.loader {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 101;
  background: white; }
  .loader h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    .loader h2 img {
      width: 200px; }

@media (min-width: 751px) {
  .menu {
    will-change: transform;
    position: fixed;
    top: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.1);
    background: white;
    width: 500px;
    height: 100%;
    z-index: 3;
    opacity: 0;
    pointer-events: none;
    transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.6s, opacity linear 0.4s;
    transform: translateX(10vh);
    will-change: transform; }
    .menu.-on {
      opacity: 1;
      pointer-events: auto;
      transform: none; }
      .menu.-on .menu-content {
        opacity: 1;
        transform: translateX(0); }
        .menu.-on .menu-content .menu-container {
          transform: translateX(0); }
      .menu.-on .menu-logo {
        opacity: 1;
        transform: translateX(0); }
      .menu.-on .menu-close {
        opacity: 1; }
    .menu .menu-logo {
      top: calc(100vw / 12 * 0.5);
      left: calc(100vw / 12 * 0.5);
      opacity: 0;
      position: absolute;
      z-index: 1;
      transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.6s; }
      .menu .menu-logo img {
        width: 200px; }
    .menu .menu-close {
      opacity: 0;
      height: 60px;
      width: 60px;
      position: absolute;
      right: 2.60417vw;
      top: calc(100vw / 12 * 0.5 - 22px); }
      .menu .menu-close:after {
        content: '';
        position: fixed;
        right: 500px;
        top: 0;
        height: 100%;
        width: calc(100vw - 500px); }
      .menu .menu-close span:nth-child(1) {
        display: inline-block;
        background: #1a1919;
        width: 100%;
        height: 1px;
        position: absolute;
        top: 30px;
        transform: rotate(24deg); }
      .menu .menu-close span:nth-child(2) {
        display: inline-block;
        background: #1a1919;
        width: 100%;
        height: 1px;
        position: absolute;
        top: 30px;
        transform: rotate(-24deg); }
    .menu .menu-content {
      opacity: 0;
      position: absolute;
      top: 280px;
      left: calc(100vw / 12 * 0.5);
      height: 100%;
      transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.6s;
      /*width: calc(100vw / 12 * 2);
            @media (min-width: 1500px) {
                width:calc(1500px / 12 * 2);
            }*/
      width: calc(1500px / 12 * 2);
      line-height: 3; }
      .menu .menu-content h3 {
        border-bottom: 1px solid #1a1919; }
      .menu .menu-content ul.menu-list li {
        border-bottom: 1px solid #1a1919; }
        .menu .menu-content ul.menu-list li a {
          opacity: 0.4;
          display: block; }
          .menu .menu-content ul.menu-list li a:hover {
            opacity: 1; }
        .menu .menu-content ul.menu-list li.-on a {
          opacity: 1; } }

@media (max-width: 750px) {
  .menu {
    will-change: transform;
    position: fixed;
    top: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.1);
    background: white;
    width: 100%;
    height: 100%;
    z-index: 3;
    opacity: 0;
    pointer-events: none;
    transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.6s, opacity linear 0.4s;
    transform: translateX(10vh);
    will-change: transform; }
    .menu.-on {
      opacity: 1;
      pointer-events: auto;
      transform: none; }
      .menu.-on .menu-content {
        opacity: 1; }
      .menu.-on .menu-logo {
        opacity: 1;
        transform: translateX(0); }
      .menu.-on .menu-close {
        opacity: 1; }
    .menu .menu-logo {
      top: 5.33333vw;
      left: 6.66667vw;
      opacity: 0;
      position: absolute;
      z-index: 1;
      transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.6s; }
      .menu .menu-logo img {
        width: 40vw; }
    .menu .menu-close {
      opacity: 0;
      height: 8vw;
      width: 13.33333vw;
      position: absolute;
      right: 6vw;
      top: 2vw; }
      .menu .menu-close span:nth-child(1) {
        display: inline-block;
        background: #1a1919;
        width: 100%;
        height: 1px;
        position: absolute;
        top: 4vw;
        transform: rotate(24deg); }
      .menu .menu-close span:nth-child(2) {
        display: inline-block;
        background: #1a1919;
        width: 100%;
        height: 1px;
        position: absolute;
        top: 4vw;
        transform: rotate(-24deg); }
    .menu .menu-content {
      opacity: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 6.66667vw;
      transition: all cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.6s;
      width: 93.33333vw;
      line-height: 3; }
      .menu .menu-content h3 {
        border-bottom: 1px solid #1a1919; }
      .menu .menu-content ul.menu-list li {
        border-bottom: 1px solid #1a1919; }
        .menu .menu-content ul.menu-list li a {
          opacity: 0.4;
          display: block; }
          .menu .menu-content ul.menu-list li a:hover {
            opacity: 1; }
        .menu .menu-content ul.menu-list li.-on a {
          opacity: 1; } }

.prlx {
  transform: translate(0, 10vw);
  opacity: 0;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.6s cubic-bezier(0, 0, 1, 1); }
  .prlx.-show {
    transform: translate(0, 0);
    opacity: 1; }

.section .section-list {
  margin-bottom: 40px; }
  .section .section-list > li {
    border-bottom: 1px solid #1a1919; }
  .section .section-list > li .section-list-ttl {
    margin-bottom: 18px; }
  .section .section-list > li .section-list-text {
    padding-right: 62.5px;
    text-align: justify;
    line-height: 2;
    margin-bottom: 30px; }
    .section .section-list > li .section-list-text.-en {
      line-height: 1.5; }
  .section .section-list > li .section-list-img {
    margin-bottom: 30px; }
    .section .section-list > li .section-list-img.-vertical img {
      width: 100%;
      max-width: 437.5px; }
    .section .section-list > li .section-list-img img {
      width: 100%; }
  .section .section-list > li .section-list-table {
    line-height: 3;
    width: 100%;
    margin-bottom: 30px; }
    .section .section-list > li .section-list-table tr {
      border-bottom: 1px solid #1a1919; }
      .section .section-list > li .section-list-table tr td:nth-child(1) {
        width: 62.5px;
        padding-right: 7.5px; }

@media (min-width: 751px) {
  .base {
    transform: rotate(45deg);
    display: flex; }
    .base::after {
      content: "";
      clear: both;
      display: block; } }
