.section{
	.section-list{
		> li{
			border-bottom:1px solid $fontColor;
		}

	        margin-bottom: s(40);

	        >li {


	            .section-list-ttl {
	                margin-bottom: s(18);
	            }

	            .section-list-text {
	            	padding-right: s(62.5);
	            	text-align: justify;
	                //width: s(570);
	                //max-width: s(504);
	                &.-en{
		                line-height: 1.5;
		            }


	                line-height: 2;
	                margin-bottom: s(30);
	            }

	            .section-list-img {
	            	&.-vertical{
	            		img{
	            			//width: auto;
	            			//height: s(500);
	            			width: 100%;
	            			max-width: s(437.5);
	            		}
	            	}
	                img {
	                    width: 100%;
	                }
	                margin-bottom: s(30);
	            }
	            .section-list-table{
	            	line-height: 3;
	            	width: 100%;

	            	tr{
	            		border-bottom: 1px solid $fontColor;
	            		td{
	            			&:nth-child(1){
	            				width:s(62.5);
	            				padding-right: s(7.5);
	            			}
	            		}
	            	}
	                margin-bottom: s(30);
	            }
	        }
	    
	}
}