
.header{
	@media #{$mq-pc} {
		.header-ttl{
			position: fixed;
			top:calc(100vw / 12 * 0.5);
			left: calc(100% / 12 * 0.5);
			img{
				width: s(200);
			}
		}
		.header-menu {
            height: s(50);
            width: s(50);
            position: fixed;
            right: w(62.5);
            transform:translateX(50%);
            top:calc(100vw / 12 * 0.5 - 13px);
            margin-bottom: s(25);
            
            span {
                &:nth-child(1) {
                    display: inline-block;
                    background: $black;
                    width: 100%;
                    height: s(1);
                    position: absolute;
                    top: s(10);
                }

                &:nth-child(2) {
                    display: inline-block;
                    background: $black;
                    width: 100%;
                    height: s(1);
                    position: absolute;
                    top: s(20);
                }

                &:nth-child(3) {
                    display: inline-block;
                    background: $black;
                    width: 100%;
                    height: s(1);
                    position: absolute;
                    top: s(30);
                }

            }
        }
	}
	@media #{$mq-sp} {
        .header-ttl{
            position: fixed;
            top:sw(40);
            left:sw(50);
            img{
                width: sw(300);
            }
        }
        .header-menu {
            height: sw(50);
            width: sw(100);
            //border:2px solid red;
            position: fixed;
            right: sw(50);
            top:sw(30);
            z-index:3;
            span {
                &:nth-child(1) {
                    display: inline-block;
                    background: $black;
                    width: 100%;
                    height: s(1);
                    position: absolute;
                    top: sw(10);
                }

                &:nth-child(2) {
                    display: inline-block;
                    background: $black;
                    width: 100%;
                    height: s(1);
                    position: absolute;
                    top: sw(20);
                }

                &:nth-child(3) {
                    display: inline-block;
                    background: $black;
                    width: 100%;
                    height: s(1);
                    position: absolute;
                    top: sw(30);
                }

            }
        }
	}
}
