.content-menu{
	position: fixed;
	top:s(280);
	left:calc(100% / 12 * 3);
	width:calc(100% / 12 * 2);
	@media #{$mq-lpc} {
		left:calc(50% - 750px + 1500px / 12 * 3);
		width:calc(1500px / 12 * 2);
	}
	line-height: 3;
	h3{
		border-bottom:1px solid $fontColor;
	}
	ul.content-menu-list{
		li{
			border-bottom:1px solid $fontColor;
			a{

                            display: block;
				opacity: 0.4;
				&:hover{
					opacity: 1;
				}
			}

			&.-on{
				a{
					opacity: 1;
				}
			}
		}
	}
	@media #{$mq-sp} {
		display: none;
	}
}

@media #{$mq-pc} {
	.content{
		padding-top: s(280);
		margin-left:calc(100% / 12 * 5.5);
		width:calc(100% / 12 * 5.5);
		@media #{$mq-lpc} {
			margin-left:calc(50% - 750px + 1500px / 12 * 5.5);
			width:calc(1500px / 12 * 5.5);
		}
		line-height: 3;

		h2.content-ttl{
			border-bottom:1px solid $fontColor;
		}
		p.content-text{
			border-bottom:1px solid $fontColor;
		}

		margin-bottom: s(60);
	}
}

@media #{$mq-sp} {
	.content{
		padding-top: 50vh;
		margin-left:sw(50);
		width:sw(700);
		line-height: 3;

		h2.content-ttl{
			border-bottom:1px solid $fontColor;
		}
		p.content-text{
			border-bottom:1px solid $fontColor;
		}

		margin-bottom: sw(60);
	}
}