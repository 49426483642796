.loader{
	position: fixed;
	top:0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 101;
	background: white;
	h2{
		position: absolute;
		top:50%;
		left: 50%;
		transform:translate(-50%,-50%);
		img{
			width: s(200);
		}
	}
}