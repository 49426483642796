
$black: #1a1919;
$fontColor: #1a1919;
$bgColor: #ffffff;
$font: -apple-system, "Segoe UI", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", メイリオ, meiryo, sans-serif;

$screen-sp: 750px;
$screen-pc: 751px;
$screen-lpc: 1500px;
$mq-sp:  "(max-width: #{$screen-sp})";
$mq-pc:  "(min-width: #{$screen-pc})";
$mq-lpc:  "(min-width: #{$screen-lpc})";