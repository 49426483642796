
body{
	@include font-smoothing()
	line-height:1;
	-webkit-text-size-adjust: 100%;
	font-size:11px;
	font-family: $font;
	color:$fontColor;
	background: $bgColor;
}

*,*::before,*::after {
	box-sizing: border-box;
}
.-transform{
	will-change: transform;
}
a{
	color: inherit;
	text-decoration: none;
}

/*
LANGUAGE / DEVICE
*/
.lang-jp [data-lang="en"]{
	display: none!important;
}
.lang-en [data-lang="jp"]{
	display: none!important;
}

@media #{$mq-pc} {
	[data-device="sp"]{
		display: none!important;
	}
}
@media #{$mq-sp} {
	[data-device="pc"]{
		display: none!important;
	}
}