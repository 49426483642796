
.footer{
	@media #{$mq-pc} {
		margin-left:calc(100% / 12 * 5.5);
		width:calc(100% / 12 * 5.5);
		@media #{$mq-lpc} {
			margin-left:calc(50% - 750px + 1500px / 12 * 5.5);
			width:calc(1500px / 12 * 5.5);
		}
		padding-bottom: calc(100vh - 280px);
	}
	@media #{$mq-sp} {

	}
}
