@media #{$mq-pc} {
    .menu {
        will-change:transform;
        position: fixed;
        top: 0;
        right: 0;
        background: rgba(255, 255, 255, 0.1);
        background: white;
        width: s(500);
        height: 100%;
        z-index: 3;
        opacity: 0;
        pointer-events: none;
        transition: all $easeInOutQuad 0.6s,opacity linear 0.4s;
        transform:translateX(10vh);
        will-change:transform;
        &.-on {
            opacity: 1;
            pointer-events: auto;
             transform:none;

            .menu-content {
                opacity: 1;
                transform: translateX(0);

                .menu-container {
                    transform: translateX(0);
                }
            }

            .menu-logo {
                opacity: 1;
                transform: translateX(0);
            }

            .menu-close {
                opacity: 1;
            }
        }

        .menu-logo {
            img {
                width: s(200);
            }
            top:calc(100vw / 12 * 0.5);
            left: calc(100vw / 12 * 0.5);

            opacity: 0;
            position: absolute;
            z-index: 1;
            //transform: translateX(60vh);
            transition: all $easeInOutQuad 0.6s;
        }

        .menu-close {
            opacity: 0;
            height: s(60);
            width: s(60);
            position: absolute;
            right: w(37.5);
            top:calc(100vw / 12 * 0.5 - 22px);
            //z-index: 1;

            &:after {
                content: '';
                position: fixed;
                right: 500px;
                top: 0;
                height: 100%;
                width: calc(100vw - 500px);
                //height: 100px;
                //width: 100px;
                //background: red;
            }

            span {
                &:nth-child(1) {
                    display: inline-block;
                    background: $black;
                    width: 100%;
                    height: s(1);
                    position: absolute;
                    top: s(30);
                    transform: rotate(24deg);
                }

                &:nth-child(2) {
                    display: inline-block;
                    background: $black;
                    width: 100%;
                    height: s(1);
                    position: absolute;
                    top: s(30);
                    transform: rotate(-24deg);
                }

            }
        }


        .menu-content {
            opacity: 0;
            position: absolute;
            top:s(280);
            left: calc(100vw / 12 * 0.5);
            height: 100%;
            transition: all $easeInOutQuad 0.6s;
            /*width: calc(100vw / 12 * 2);
            @media #{$mq-lpc} {
                width:calc(1500px / 12 * 2);
            }*/
            width:calc(1500px / 12 * 2);
            line-height: 3;
            h3{
                border-bottom:1px solid $fontColor;
            }
                ul.menu-list{
                    li{
                        border-bottom:1px solid $fontColor;
                        a{
                            opacity: 0.4;
                            display: block;
                &:hover{
                    opacity: 1;
                }
                        }
                        &.-on{
                            a{
                                opacity: 1;
                            }
                        }
                    }
                }
            
        }
    }
}

@media #{$mq-sp} {
    .menu {
        will-change:transform;
        position: fixed;
        top: 0;
        right: 0;
        background: rgba(255, 255, 255, 0.1);
        background: white;
        width: 100%;
        height: 100%;
        z-index: 3;
        opacity: 0;
        pointer-events: none;
        transition: all $easeInOutQuad 0.6s,opacity linear 0.4s;
        transform:translateX(10vh);
        will-change:transform;
        &.-on {
            opacity: 1;
            pointer-events: auto;
             transform:none;

            .menu-content {
                opacity: 1;
            }

            .menu-logo {
                opacity: 1;
                transform: translateX(0);
            }

            .menu-close {
                opacity: 1;
            }
        }

        .menu-logo {
            top:sw(40);
            left:sw(50);
            img{
                width: sw(300);
            }

            opacity: 0;
            position: absolute;
            z-index: 1;
            //transform: translateX(60vh);
            transition: all $easeInOutQuad 0.6s;
        }

        .menu-close {
            opacity: 0;
            height: sw(60);
            width: sw(100);
            position: absolute;
            right: sw(45);
            top:sw(15);
            //z-index: 1;



            span {
                &:nth-child(1) {
                    display: inline-block;
                    background: $black;
                    width: 100%;
                    height: s(1);
                    position: absolute;
                    top: sw(30);
                    transform: rotate(24deg);
                }

                &:nth-child(2) {
                    display: inline-block;
                    background: $black;
                    width: 100%;
                    height: s(1);
                    position: absolute;
                    top: sw(30);
                    transform: rotate(-24deg);
                }

            }
        }


        .menu-content {
            opacity: 0;
            position: absolute;
            top:50%;
            transform:translateY(-50%);
            left: sw(50);
            //height: 100%;
            transition: all $easeInOutQuad 0.6s;
            width:sw(700);
            line-height: 3;
            h3{
                border-bottom:1px solid $fontColor;
            }
                ul.menu-list{
                    li{
                        border-bottom:1px solid $fontColor;
                        a{
                            opacity: 0.4;
                            display: block;
                &:hover{
                    opacity: 1;
                }
                        }
                        &.-on{
                            a{
                                opacity: 1;
                            }
                        }
                    }
                }
            
        }
    }
}