.acc{
	cursor:pointer;
	position: relative;
	.acc-header{
		display: flex;
		span{
			display: inline-block;
			&:nth-child(1){
				width: s(62.5);
			}
			&:nth-child(2){
				width: s(62.5);
			}
			&:nth-child(3){
				//width: s(250);
			}

		}
	}
	.acc-content{
		width: 100%;
		padding-left: s(125);
		padding-top: s(20);
		line-height: 1.75;
		@media #{$mq-pc} {
			//padding-bottom: s(20);
		}
		@media #{$mq-sp} {
			padding-bottom: sw(0);
		}

	}
	@media #{$mq-pc} {
		&:after{
			pointer-events:none;
			opacity: 0;
			content:'';
			background: #fff;
			background: #eaeaea;
			position: absolute;
			top:0;
			left: 0;
			width: 100%;
			height: 100%;
			transition:0.2s all linear;
                mix-blend-mode: darken;
		}
		&:hover{
			&:after{
				opacity: 0.4;
			}
		}
		&.-open{
			cursor:auto;
			&:hover{
				&:after{
					opacity: 0;
				}
			}
		}
		.acc-close:hover{
			opacity: 0.4;
		}
		.acc-close{
                transition:0.2s all linear;
                height: s(40);
                mix-blend-mode: darken;
                width: 100%;
                background: #eaeaea;
                //z-index: -1;
                position: absolute;
                top:0;
                left: 0;
                cursor: pointer;
                opacity: 0;
            }
	}
	@media #{$mq-sp} {
		.acc-close{
                transition:0.2s all linear;
                height: sw(100);
                mix-blend-mode: darken;
                width: 100%;
                background: #eaeaea;
                //z-index: -1;
                position: absolute;
                top:0;
                left: 0;
                cursor: pointer;
                opacity: 0;
            }
	}
}